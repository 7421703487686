
import { parse } from 'marked'
import HeadingSM from '@/components/wrappers/HeadingSM.vue'
// import JobList from '@/components/parts/jobs/JobList.vue'
import jobRolesQuery from '@/apollo/queries/jobRoleExternals'
import VerticalLongSVG from '@/components/svg/detail/VerticalLongSVG.vue'
import Saw from '@/components/parts/Saw.vue'
import JobListing from '@/components/parts/jobs/JobListing.vue'
import JobListingExternal from '@/components/parts/jobs/JobListingExternal.vue'
import BtnPrimaryLink from '@/components/parts/btn/BtnPrimaryLink.vue'
export default {
  components: {
    HeadingSM,
    // JobList,
    VerticalLongSVG,
    Saw,
    JobListing,
    JobListingExternal,
    BtnPrimaryLink,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      jobs: [],
      tl: null,
      noJobs: false,
      loaded: false,
    }
  },

  async fetch() {
    try {
      const client = this.$apollo.getClient()
      const response = await client.query({
        query: jobRolesQuery,
      })
      if (response?.data?.jobRoleExternals?.data) {
        this.jobs = response.data.jobRoleExternals.data
        if (!this.jobs.length) this.noJobs = true
        this.$nextTick(() => {
          // this.$ScrollTrigger.refresh()
          // this.$nuxt.$emit('refreshSaws')
          this.go()
        })
      }
    } catch (e) {
      this.articles = []
      this.loading = false
    }
  },

  computed: {
    renderedBody() {
      return this.section.body ? parse(this.section.body) : ''
    },
  },
  fetchOnServer: false,
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        start: 'top bottom',
        // toggleActions: 'play reset play reverse',
      },
      onComplete: () => {
        heading.revert()
        subheading.revert()
      },
    })
    this.tl.scrollTrigger.disable()

    !this.dark && this.tl.to(this.$refs.mask, { xPercent: -100, duration: 0.3 })

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
    this.tl.from(heading.chars, {
      opacity: 0,
      stagger: {
        each: 0.1,
        from: 'start',
        grid: 'auto',
        ease: 'power2.inOut',
      },
    })

    const subheading = new this.$SplitText(this.$refs.subheading, { type: 'chars, words, lines' })
    this.$refs.subheading &&
      this.tl.from(
        subheading.words,
        {
          opacity: 0,
          stagger: {
            each: 0.1,
            from: 'start',
            grid: 'auto',
            ease: 'power2.inOut',
          },
        },
        '<'
      )

    this.$refs.body && this.tl.from(this.$refs.body, { opacity: 0, duration: 0.4 }, '>')

    // wait to give time for careers to load, if not loaded within time, go anyway
    setTimeout(() => {
      this.go()
    }, 500)

    // this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    go() {
      if (!this.loaded) {
        this.loaded = true
        this.$emit('sectionMounted')
      }
    },
  },
}
