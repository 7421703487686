
import BtnPrimary from '@/components/parts/btn/BtnPrimary.vue'
import ListingSVG from '@/components/svg/jobs/ListingSVG.vue'
import ListingMBSVG from '@/components/svg/jobs/ListingMBSVG.vue'
import ListingSideSVG from '@/components/svg/jobs/ListingSideSVG.vue'
export default {
  components: {
    BtnPrimary,
    ListingSVG,
    ListingSideSVG,
    ListingMBSVG,
  },
  props: {
    job: {
      type: Object,
      default: () => {},
    },
    dark: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tl: null,
      // isEnabled: false,
    }
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        // markers: true,
        // toggleActions: 'play reset play reverse',
      },
      onComplete: () => {
        heading.revert()
      },
    })
    // !this.isEnabled && this.tl.scrollTrigger.disable()

    this.tl.from(this.$refs.svg.$el.children, { drawSVG: 0, stagger: 0.1, duration: 0.1 }, '>')
    this.tl.from(this.$refs.listing.$el, { backgroundColor: 'transparent', duration: 0.1 }, '<')

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines' })
    this.tl.from(
      heading.chars,
      {
        opacity: 0,
        stagger: {
          each: 0.01,
          from: 'start',
          // grid: 'auto',
          ease: 'power2.inOut',
        },
      },
      '>'
    )
    this.tl.from(this.$refs.info, { opacity: 0, yPercent: -10, duration: 0.2 }, '>')
    this.$refs.btn && this.tl.from(this.$refs.btn.$el, { opacity: 0, duration: 0.2 }, '>')
    this.$refs.side && this.tl.from(this.$refs.side.$el, { opacity: 0, xPercent: -20, duration: 0.2 }, '<')

    // this.$emit('sectionMounted')
  },
  // beforeMount() {
  //   this.$nuxt.$on('go', this.anim)
  // },
  // beforeDestroy() {
  //   this.$nuxt.$off('go', this.anim)
  // },
  // methods: {
  //   anim() {
  //     console.log('anim')
  //     if (!this.isEnabled) {
  //       this.isEnabled = true
  //     } else {
  //       this.tl.scrollTrigger.enable()
  //     }
  //   },
  // },
}
