import { gql } from 'graphql-tag'
import { LinkFragment } from './fragments/parts.js'
export default gql`
  query {
    jobRoleExternals(sort: "title:asc") {
      data {
        id
        attributes {
          title
          type {
            data {
              id
              attributes {
                type
              }
            }
          }
          locations {
            data {
              id
              attributes {
                location
              }
            }
          }
          link {
            ...LinkFragment
          }
        }
      }
    }
  }
  ${LinkFragment}
`
